.file-upload {
	border: 2px dashed transparent;

	&.focused {
		border-color: #b6d4fe;
	}

	ul {
		cursor: pointer;
		padding-left: 7.75rem;

		&::before {
			content: "Choose Files";
			padding: 0.375rem 0.75rem;
			border-radius: 0.25rem 0 0 0.25rem;
			display: flex;
			align-items: center;
			border-right: 1px solid #ced4da;
			background-color: var(--bs-gray-200);
			position: absolute;
			left: 0;
			height: 100%;
			margin-top: -0.375rem;
		}

		&:hover::before {
			background-color: var(--bs-gray-300);
		}

		li:not(:last-child)::after {
			content: ", ";
			margin-right: 0.2rem;
		}
	}
}
