.set-plan {
  label {
    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
  }

  input.form-range {
    width: 80%;
    justify-self: center;
    align-self: center;
  }
}