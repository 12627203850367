.account-report {
	.summary {
		h4 {
			font-size: 1rem;
		}

		p {
			font-size: 1.4rem;
		}
	}

	.summary-lists {
		min-height: 9.4rem;

		h4 {
			font-size: 1rem;
		}

		a {
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.chart-container {
		display: flex;
		justify-content: center;
		align-items: center;

		h5 {
			font-size: 1rem;
		}

		[role=figure] {
			width: 90%;
			align-self: center;
			justify-content: center;
			align-items: center;
		}
	}
}
