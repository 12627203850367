.loader {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: calc(100% - 4rem);
  left: 0;
  top: 4rem;
  z-index: 110;
  overflow: hidden;
  animation: makeVisible 0.1s linear 1s forwards;

  & > img {
    height: 10%;
    animation: pulse 2s infinite ease-in-out 1s;
  }
}

@keyframes makeVisible {
  0% { visibility: hidden; opacity: 0; }
  100% { visibility: visible; opacity: 1; }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}