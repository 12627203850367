@import "../../styles/Variables";
@import "~bootstrap/scss/bootstrap";

.login-page {
  background: rgba(33, 53, 85, 1);
  min-height: 100vh;
  min-width: 100vw;
  height: 100%;
  display: flex;

  h1 {
    font-family: 'Quicksand', $site-font-family;
    font-weight: normal;
    text-align: center;
  }

  h2 {
    font-family: 'Comfortaa', $site-font-family;
    color: rgba(28, 25, 72, 1);
    font-size: 3rem;
  }

  h3 {
    font-family: 'Quicksand', $site-font-family;
    font-weight: normal;
  }
}

.login {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  background: #e6dcc6;

  .info-panel {
    border-top: 1px solid rgba(33, 53, 85, 1);
  }

  .login-panel {
    background: transparent url("../../images/boarded-logo.svg") no-repeat 50% 10%;
    background-size: 10rem;
    padding-top: 14rem;
  }
}

@media (min-width: 768px) {
  .login .info-panel {
    border-top: none;
    border-right: 1px solid rgba(33, 53, 85, 1);
  }
}
