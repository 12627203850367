.select-labels {
	.suggestion {
		display: block;
		margin-top: -31px;
		margin-left: 13px;
		opacity: 40%;
		position: fixed;
	}

	.advice {
		position: absolute;
		right: 1rem;
		margin-top: -31px;
		text-align: right;
		opacity: 40%;
	}
}
