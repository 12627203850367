@import "~bootstrap/scss/bootstrap";

.board-progress-chart {
	width: 100vw;
	position: fixed;
	bottom: 0;

	& > div {
		background: #B0BA2F;
		height: 0.4rem;
		border-radius: 0 6px 6px 0;
	}
}

@include media-breakpoint-up(md) {
	.board-progress-chart {
		width: calc(100vw - 15rem);
	}
}
