@import "~bootstrap/scss/bootstrap";

.task-list-chart {
	width: 100vw;
	position: fixed;
	display: flex;
	border-radius: 6px;
	z-index: 1;

	& > div {
		height: 0.4rem;
	}

	.task-list-1 {
		background: #BD2B23;
	}

	.task-list-2 {
		background: #4181BA;
	}

	.task-list-3 {
		background: #B0BA2F;
	}
}

@include media-breakpoint-up(md) {
	.task-list-chart {
		width: calc(100vw - 15rem);
	}
}
