.select-users {
	position: relative;
	width: 100%;

	.user-list {
		list-style-type: none;
		list-style-position: outside;
		display: flex;
		flex-direction: column;
		margin: 3px 0 0 0;
		padding: 0;
		max-height: 12rem;
		overflow-y: auto;
		background: white;
		position: absolute;
		z-index: 1;
		box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
		cursor: pointer;

		li {
			padding: 0.8rem 1.2rem;

			&:hover, &.selected {
				background-color: #e5e5e5;
			}
		}
	}
}
