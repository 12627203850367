.error-toast {
	.toast-header {
		display: flex;
		justify-content: space-between;
	}

	h4 {
		font-size: 1rem;
	}
}
