@import "~bootstrap/scss/bootstrap";

.App {
	.page-content {
		height: 100vh;
		overflow-x: auto;
		padding-top: 7.7rem;
	}

	.col-boards-nav {
		padding: 0;
	}
}

@include media-breakpoint-up(md) {
	.App .page-content {
		padding-top: 4rem;
	}
}