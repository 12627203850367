.user-list {
	display: flex;
	justify-content: flex-start;
	gap: 0.2rem;

	img {
		border-radius: 50%;
	}

	.extra-users {
		height: 32px;
		width: 32px;
		border-radius: 50%;
		background-color: rgba(28, 25, 72, 1) !important;
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.1rem;
		cursor: default;
	}
}
