.recent-events {
	h4 {
		font-size: 1.3rem;
	}

	a {
		color: #4f5050;
		text-decoration: none;

		&:hover {
			color: #4f5050;
			text-decoration: underline;
		}
	}
}
