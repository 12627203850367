.view-task {
	.view-task-header {
		.status {
			position: absolute;
			right: 4rem;
			top: 0.7rem;
		}

		.user-list {
			position: absolute;
			left: 2rem;
			top: 0.7rem;
			justify-content: flex-start;
		}
	}

	.edit-task {
		width: 80%;
	}

	.description {
		br {
			font-size: 0;
		}

		p {
			margin-bottom: 0;
		}
	}

	.missing-description {
		font-style: italic;
	}

	h3 {
		font-size: 1.3rem;
	}
}
