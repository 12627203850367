@import 'react-datepicker/dist/react-datepicker.css';

.react-datepicker__close-icon {
	&::after {
		content: "\f622";
		font-family: "bootstrap-icons" !important;
		font-size: 1.2rem;
		color: var(--bs-blue);
		background: white !important;
	}
}
