.task-card {
	.card-title {
		margin-bottom: 0.3rem;

		h5 {
			margin-bottom: 0;
		}
	}

	.status {
		font-size: 0.6rem;
		position: absolute;
		bottom: 0.3rem;
		right: 0.3rem;
	}

	.labels {
		line-height: 0.6rem;

		.label {
			font-size: 0.6rem;
			margin-bottom: 1rem !important;
		}
	}
}
