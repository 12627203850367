@import "../../styles/Variables";

.site-nav {
	&.fixed-top {
		z-index: 1040;
	}

	&.bg-dark {
		background-color: rgba(33, 53, 85, 1) !important;
	}

	a.navbar-brand {
		background: url("../../images/boarded-logo-circle.svg") no-repeat 0.5rem 50%;
		background-size: 2.2rem;
		padding: 0 0 0 3.2rem;
		font-family: 'Comfortaa', $site-font-family;
		line-height: 3rem;
	}

	.navbar-collapse.collapse:not(.show) {
		.nav-link {
			margin-top: -3px;
		}
	}

	.react-gravatar {
		border-radius: 50%;
	}
}
