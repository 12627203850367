.task {
	padding-top: 4rem;

	.view-task {
		.view-task-header {
			.user-list {
				left: 1rem;
			}

			.status {
				right: 1rem;
			}
		}
	}
}
