@import "../../styles/Variables";

.setup-page {
	background: rgba(33, 53, 85, 1);
	background-size: 9.2rem;
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;

	h1 {
		font-family: 'Comfortaa', $site-font-family;
		color: rgba(33, 53, 85, 1);
		font-size: 3rem;
	}

	.setup-title {
		background: transparent url("../../images/boarded-logo-light.svg") no-repeat 0 0;
		background-size: contain;
		padding-left: 6.6rem;
	}

	.card-title {
		margin: -0.5rem -0.5rem 2rem;
	}

	.setup {
		width: 80vw;
		height: 80vh;
	}
}

@media (min-width: 768px) {
	.setup-page .col-md-border {
		&:not(:last-child) {
			border-right: 1px solid #d7d7d7;
		}
	}
}
