@import "~bootstrap/scss/bootstrap";

.edit-board-task, .offcanvas.edit-board-task.offcanvas-end {
	.edit-task {
		width: 90%;
	}

	.view-task {
		.labels-container {
			width: 50%;
			margin-left: -2rem;
		}
	}
}

.board-header {
	min-height: 5.4rem;
}

.board-container {
	position: relative;

	.archived-board {
		z-index: 10;
		position: absolute;
		top: 0;
		left: 0;
		margin-left: -1rem;
		height: calc(100% + 5rem);
		width: calc(100% + 1.75rem);
		background-color: rgba(0, 0, 0, 0.2);

		& > * {
			width: 100%;
			height: 100%;
		}

		.reactivate-board {
			min-width: 18rem;
			min-height: 12rem;
		}
	}
}

@include media-breakpoint-up(md) {
	.edit-board-task, .offcanvas.edit-board-task.offcanvas-end {
		width: 50vw;
	}

	.create-task, .offcanvas.create-task.offcanvas-end {
		width: 50vw;
	}

	.offcanvas.board-chat-container.offcanvas-end {
		width: 50vw;
	}
}
