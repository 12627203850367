@import "~bootstrap/scss/bootstrap";

.file-preview {
	button.btn-close {
		font-size: 0;
		line-height: 1rem;
	}

	.preview-container {
		height: 80vh;
	}

	video {
		height: auto;
		width: 100%;
	}

	img {
		height: auto;
		width: 100%;
	}

	.no-preview {
		cursor: pointer;

		i {
			font-size: 12rem;
		}

		p {
			margin-top: -0.5rem;
		}
	}
}

@include media-breakpoint-up(md) {
	.file-preview {
		video {
			height: 100%;
			width: auto;
		}

		img {
			height: 100%;
			width: auto;
		}
	}
}