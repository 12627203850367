.bank-card {
	min-height: 8rem;

	.card-options {
		border-left: 1px solid rgba(0, 0, 0, 0.125);

		button {
			font-size: 0;
			padding: 0;
			color: var(--bs-gray-500);

			&:hover {
				color: var(--bs-dark);

				.count-preview {
					color: var(--bs-dark);
				}
			}

			& > i {
				font-size: 1rem;
				margin: 0;
			}

			.count-preview {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				color: var(--bs-gray-500);
				font-size: 0.6rem;
			}

			.high-count-preview {
				font-size: 0.4rem;
			}
		}
	}
}
