.label, .label.badge {
	display: inline-flex;
	align-items: center;
	line-height: 1;

	button {
		display: flex;
		justify-content: center;
		font-size: 0.9rem;
		line-height: 1;
		margin-left: 0.3rem;
	}
}
