.user-card {
  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  button {
    font-size: 0;
    padding: 0;
    color: var(--bs-gray-500);

    &:hover {
      color: var(--bs-dark);

      .count-preview {
        color: var(--bs-dark);
      }
    }

    & > i {
      font-size: 1rem;
      margin: 0;
    }
  }
}