@import "~bootstrap/scss/bootstrap";

.boards-nav {
	--bg-colour: #ececec;

	flex-direction: column;
	overflow-x: auto;
	background: var(--bg-colour);
	border-right: 1px solid rgba(0, 0, 0, 0.15);
	box-shadow: 3px 0 4px 0 rgba(0,0,0,0.15);

	&.fixed-top {
		top: 4rem;
	}

	.nav-item {
		width: 100%;
	}

	.nav-link.selected {
		font-weight: bold;
	}

	form {
		display: flex;
		justify-content: center;
		padding: 1rem 0;
		position: sticky;
		top: 0;
		background: var(--bg-colour);
	}

	input[type=text] {
		width: 90%;
	}

	button, a {
		color: #4f5050;
		text-decoration: none;

		&:hover, &:focus, &:active, &:visited {
			color: #4f5050;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	.create-customer {
		width: 100%;
		display: flex;
		justify-content: center;
		position: sticky;
		bottom: 0;
		background: var(--bg-colour);
		padding: 0.5rem 0;
	}

	.btn-create-customer {
		i {
			margin-right: 4px;
		}
	}
}

@include media-breakpoint-up(lg) {
	.boards-nav {
		height: 100%;
		min-height: calc(100vh - 4rem);
		max-height: calc(100vh - 4rem);

		&.fixed-top {
			position: relative;
			top: 4rem;
		}
	}
}
