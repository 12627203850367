.comment {
	.text .comment {
		br {
			font-size: 0;
		}

		p {
			margin-bottom: 0;
		}
	}

	.actions {
		button {
			font-size: 0;
			padding: 0;
			line-height: 0;
			height: 1rem;
			width: 1rem;
			display: flex;
			justify-content: center;
			align-items: center;

			&:hover {
				color: var(--bs-dark) !important;
			}

			i {
				font-size: 0.9rem;
				line-height: 0;
			}

			&.remove-comment i {
				font-size: 1.4rem;
			}
		}
	}

	footer {
		font-size: 0.8rem;
		border-top: 1px dashed rgba(0, 0, 0, 0.05);

		.author {
			img {
				border-radius: 50%;
			}
		}

		& > p {
			color: rgba(0, 0, 0, 0.9);
		}
	}
}
