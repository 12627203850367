.chat-loader {
  visibility: hidden;
  opacity: 0;
  animation: makeVisible 0.1s linear 1s forwards;
  height: 12rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    height: 4rem;
    animation: pulse 2s infinite ease-in-out 1s;
  }
}

@keyframes makeVisible {
  0% { visibility: hidden; opacity: 0; }
  100% { visibility: visible; opacity: 1; }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}