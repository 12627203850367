.task-list {
	background-color: #e5e5e5;
	min-height: 14rem;

	.items {
		display: flex;
		flex-direction: column;
	}

	.card {
		margin-top: 0.5rem;
	}

	a, a:hover, a:visited, a:focus, a:active {
		text-decoration: none;
		color: var(--bs-gray-900);
	}
}
