.file-list {
	.file {
		position: relative;

		&.deleting::after {
			content: '\F622';
			font-family: "bootstrap-icons";
			font-size: 0.8rem;
			position:absolute;
			top: -0.4rem;
			right: -0.1rem;
		}
	}

	.btn-delete:hover {
		color: var(--bs-dark) !important;
	}
}
