.view-template {
  .description {
    br {
      font-size: 0;
    }

    p {
      margin-bottom: 0;
    }
  }
}