.event-card {
	position: relative;

	.card {
		min-height: auto;
	}

	.badge {
		font-size: 0.6rem;
		position: absolute;
	}

	.event-type {
		bottom: 0.6rem;
		left: 50%;
		transform: translateX(-50%);
	}

	.event-date {
		top: 0.3rem;
		right: 0.3rem;
	}
}

a.event-card, .recent-events a.event-card {
	&:hover {
		text-decoration: none;
	}
}

.tooltip {
	top: 0;
	left: 0;
}
