.impersonate-bar {
	background-color: rgba(127, 119, 183, 1);
	width: 100%;
	font-size: 0.8rem;
	line-height: 1.4rem;
	color: white;
	font-weight: bold;
	margin: 0;
	text-align: center;
	position: absolute;
	bottom: 0;
	cursor: pointer;
}
