.side-bar {
	.btn-side-bar {
		position: absolute;
		bottom: 1rem;
		left: 50%;
		transform: translateX(-50%);
	}

	.labels-container {
		position: fixed;
		bottom: 0;
		margin-left: calc(var(--bs-gutter-x) * -0.5 - 1rem);
		background: white;
		box-sizing: border-box;
		z-index: 1;
	}
}
