@import "~bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons";
@import "App";

@import "./styles/Variables";
@import "react-quill/dist/quill.snow.css";

@font-face {
  font-family: 'Comfortaa';
  src: local('Comfortaa'), url(./fonts/Comfortaa-VariableFont_wght.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: $site-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fs-0 {
  font-size: 0 !important;
  line-height: 1rem;
}
