@import "~bootstrap/scss/bootstrap";

@include media-breakpoint-up(md) {
	.view-template-panel, .offcanvas.view-template-panel.offcanvas-end {
		width: 50vw;
	}

	.create-template, .offcanvas.create-template.offcanvas-end {
		width: 50vw;
	}
}
