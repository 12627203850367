.invite-members {
	.invitation {
		display: flex;

		a {
			max-width: 100%;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
	}
}
